import en from './app/config/locales/en.json'
import fr from './app/config/locales/fr.json'
import nl from './app/config/locales/nl.json'

export default defineI18nConfig(() => ({
  fallbackLocale: 'fr',
  locale: 'fr',
  messages: {
    en,
    fr,
    nl
  }
}))
